<div class="tn-login-wrapper base-bg">
  <ng-container *ngIf="!useNewLoginPageUI">
    <div class="login-header login-page-header">
      <div class="tn-logo login-logo"> 
        <div class="tn-title">
          {{'GENERAL.TEAMNOTE' | translate}}
        </div>
      </div>
    </div>
    <div class="system-label" *ngIf="SYSTEM_LABEL">
      {{SYSTEM_LABEL}}
    </div>
    <div class="site-label" *ngIf="SITE_LABEL">
      {{SITE_LABEL}}
    </div>
    <div class="build-label" *ngIf="BUILD_LABEL">
      {{BUILD_LABEL}}
    </div>
  </ng-container>
  <div class="login-body-wrapper login-page-body" [ngClass]="{'new-ui': useNewLoginPageUI}">
    <div class="login-card" [ngClass]="{'login-card-new-ui': useNewLoginPageUI}">
      <div class="login-header login-page-header" *ngIf="useNewLoginPageUI">
        <div class="tn-logo login-logo"> 
          <!-- <div class="tn-title">
            {{'GENERAL.TEAMNOTE' | translate}}
          </div> -->
        </div>
        <div class="tn-title">
          {{'GENERAL.TEAMNOTE' | translate}}
        </div>
      </div>

      <div class="login-body" [ngClass]="{'new-ui-login-body': useNewLoginPageUI}">
        <router-outlet></router-outlet>
      </div>

      <div class="login-footer" [ngClass]="{'new-ui-color': useNewLoginPageUI}">
        <div class="version-number">
          <span>{{VERSION_NO}}</span>
        </div>
        <div class="copyright-wording">
          {{'GENERAL.POWER_BY_MSG' | translate}}
        </div>
        <div class="remark-info" *ngIf="useNewLoginPageUI">
          <span [innerHTML]="'LOGIN.GET_STATE.REMARKS' | translate"></span>
        </div>
      </div>
    </div>
  </div>
</div>