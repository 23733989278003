import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TeamnoteConfigService } from './configs/teamnote-config.service';
import { LanguageConstant } from './constants/language.constant';
import { TeamNoteLocalStorageKeyConstants } from './constants/local-storage-key.constant';
import { StringHelperService } from './strings/string-helper.service';
import { ContextMenuService } from './utilities/context-menu/context-menu.service';
import { LocalStorageManagerService } from './utilities/local-storage/local-storage-manager.service';
import { LoggerService } from './utilities/logger/logger.service';
import { TnNotificationService } from './utilities/tn-notification/tn-notification.service';
import { TranslateManagerService } from './utilities/translate/translate-manager.service';
import { VersionCheckingService } from './utilities/version-checking/version-checking.service';
import { WebsiteTitleService } from './utilities/website-title/website-title.service';
import { WindowFocusService } from './utilities/window-focus/window-focus.service';
import { BaseRoutingService } from './routing/base-routing.service';
import { TnLinkHelperService } from './utilities/tn-link-helper/tn-link-helper.service';
import { OauthService } from './login/oauth.service';
import { LoginMethodConstant } from './login/constants/login-methods.constant';
import { SamlService } from './login/saml.service';
import { UtilitiesService } from './utilities/service/utilities.service';
import { ModuleManagerService } from './webclient/services/module/module-manager.service';
import { ModuleKeyDefinition } from './constants/module.constant';
import { TeamNoteGeneralConstant } from './constants/general.constant';
import { AccountManagerService } from './webclient/services/account/account-manager.service';
import { VersionConfig } from './configs/version.config';
import {CookieService} from 'ngx-cookie';

import { SocketService } from './webclient/services/socket/socket.service';
import { WebclientService } from './webclient/webclient.service';
import { TnLoaderService } from './utilities/tn-loader/tn-loader.service';
import { EmojiService } from './utilities/emoji/emoji.service';

@Component({
  selector: 'tn-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title: string;
  tempPageHeader: string;
  languageKeyOption: string[];

  isInitializing: boolean;

  reconnectTimer: any = null;

	constructor(
    private _websiteTitleService: WebsiteTitleService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _translateManagerService: TranslateManagerService,
    private _loggerService: LoggerService,
    private _contextMenuService: ContextMenuService,
    private _tnNotificationService: TnNotificationService,
    private _renderer: Renderer2,
    private _windowFocusService: WindowFocusService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _versionCheckingService: VersionCheckingService,
    private _stringHelperService: StringHelperService,
    private _baseRoutingService: BaseRoutingService,
    private _tnLinkHelperService: TnLinkHelperService,
    private _oauthService: OauthService,
    private _samlService: SamlService,
    private _utilitiesService: UtilitiesService,
    private _moduleManagerService: ModuleManagerService,
    private _accountManagerService: AccountManagerService,
    private _cookieService: CookieService,
    private _socketService: SocketService,
    private _webclientService: WebclientService,
    private _tnLoaderService: TnLoaderService,
    private _emojiService: EmojiService
  ) {
    this._loggerService.debug('App starts initializing...');
    this.isInitializing = true;

    this._loggerService.debug('Trying to request browser notification...');
    this._tnNotificationService.tryToRequestNotificationPermission();
  }

  ngOnInit() {
    this._loggerService.debug('App ngOnInit');

    this.handleExternalAuthMethod();

    if (window.name === TeamNoteGeneralConstant.POP_UP_WINDOW_NAME) {
      try {
        let tempIsClosedFromSelfField = window.opener ?
          window.opener.document.getElementById(TeamNoteGeneralConstant.POP_UP_CHECK_SELF_CLOSED_ID) : false;
        if (tempIsClosedFromSelfField) {
          tempIsClosedFromSelfField.value = "1";
        }
      } catch (e) {
        console.log(e);
      }
      window.close();
      return;
    }

    const hash = window.location.hash.substr(1);
    if (hash) {
      this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE, hash);
    }

    // Super Connector
    const injectedAuthToken = this._cookieService.get(TeamNoteLocalStorageKeyConstants.SUPER_CONNECTOR.AUTH_TOKEN);
    // const injectedAuthToken = 'WEB_CLIENT_neshCt_gSKaS-8CzAsZ3Rw';
    if (injectedAuthToken) {
      this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN, injectedAuthToken);
      this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_PRESERVE_COOKIES.TNC_LAST_ACCEPT_TIME, new Date().getTime() / 1000);
    }
    const injectedDeviceToken = this._cookieService.get(TeamNoteLocalStorageKeyConstants.SUPER_CONNECTOR.DEVICE_TOKEN);
    if (injectedDeviceToken) {
      this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DEVICE_TOKEN, injectedDeviceToken);
    }

    this._teamnoteConfigService.getTnConfigFromJSON(
      () => {
        this.storeCompanyDomain();
        this.applyThemeToBody();
        this.setUpWebClientStrings();

        this._emojiService.installAppEmojiSet();

        this.initVersionCheckingTimestamp();
        this.performVersionMigration(this._teamnoteConfigService.config.VERSION.VERSION_CODE);

        this._websiteTitleService.setWebsiteTitle(null);
        this.isInitializing = false;

        this.goToLoginPage();
        this.handleWindowOnClickForTeamNoteLinks();
      }
    );
  }

  handleExternalAuthMethod(): void {
    const authType = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_TYPE);

    switch (authType) {
      case LoginMethodConstant.AUTH_TYPE.OAUTH:
        this._oauthService.tryToExtractOAuthAccessToken(window.location.hash);
        return;
      case LoginMethodConstant.AUTH_TYPE.SAML:
        return;
      default:
        return;
    }
  }

  @HostListener('window:beforeunload') onWindowBeforeUnload() {
    this._loggerService.debug('Window is being unload');
    this.clearClipboard();
  }
  @HostListener('window:focus', ['$event']) windowOnFocus(event: any) {
    this.checkVersion();
    // console.log('window:focus')
    // this._webclientService.updatePageVisibleSubject(true)

    // this._socketService.checkIfNeedToReconnect();
    
    this._windowFocusService.onWindowFocusEvent();
  }
  @HostListener('window:blur', ['$event']) windowOnBlur(event: any) {
    // console.log('window:blur')
    // this._webclientService.updatePageVisibleSubject(false)
    
    this._windowFocusService.onWindowBlurEvent();
    this.clearClipboard();
  }
  @HostListener('document:visibilitychange', ['event']) visibilityChange(event: any) {
    if (document.hidden) {
      /* Page is hidden now */
      this._loggerService.debug("Page is hidden now - inactive status");

      // console.log( 'OnPageHidden => hidden' );
      this._webclientService.updatePageVisibleSubject(false)
    } else {
      /* Page is visible now */
      this._loggerService.debug("Page is visible now - active status");

      // console.log('OnPageVisible => visible --- socket is connected now?', this._socketService._isConnected)
      this._webclientService.updatePageVisibleSubject(true)
      this._webclientService.updateReconnectionStopCountSubject(0)

      // try to reconnect ws after 3s of page visible
      this._socketService.checkIfNeedToReconnect();
    }
  }

  storeCompanyDomain() {
    this._loggerService.debug('Extracting company domain...');
    const domain = this._teamnoteConfigService.config.HOST.DOMAIN;
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DOMAIN, domain);
    this._loggerService.debug('Stored company domain in Cookies: [[[ {domain} ]]'.replace('{domain}', domain));
  }

  applyThemeToBody() {
    let domain = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DOMAIN);
    if (this._teamnoteConfigService.config.HOST.CONFIG_DOMAIN) {
      domain = this._teamnoteConfigService.config.HOST.CONFIG_DOMAIN;
    }
    const themeName = 'theme-' + domain;
    this._renderer.addClass(document.body, themeName);
    this._loggerService.debug('Set theme [[[ {themeName} ]]] on <body> tag'.replace('{themeName}', themeName));
  }

  /**
   * Set up strings of this company and set default language
   *
   * @returns {void}
   * @memberof AppComponent
   */
  setUpWebClientStrings(): void {
    this._loggerService.debug('Adding i18n strings setting...');
    let domain = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DOMAIN);
    if (this._teamnoteConfigService.config.HOST.CONFIG_DOMAIN) {
      domain = this._teamnoteConfigService.config.HOST.CONFIG_DOMAIN;
    }
    const allStrs = this._stringHelperService.getAllCompanyStrings(domain);
    this._translateManagerService.addTranslationByLanguageStringArr(allStrs);

    this._loggerService.debug('Setting default language by detection');
    let lang = null;
    // Always Detect browser language
    const browserLang = navigator.language;
    switch (browserLang) {
      case 'zh-CN':
      case 'zh-SG':
        lang = LanguageConstant.ZH_CHS.key;
        break;
      case 'zh':
      case 'zh-TW':
      case 'zh-HK':
        lang = LanguageConstant.ZH_CHT.key;
        break;
      default:
        lang = LanguageConstant.EN.key;
        break;
    }

    const defaultLang = this._teamnoteConfigService.config.LANGUAGE.DEFAULT;
    this._translateManagerService.setDefaultLang(defaultLang || LanguageConstant.EN.key);

    if (defaultLang) {
      return;
    }
    
    this._translateManagerService.changeLang(lang);
  }


  goToLoginPage(): void {
    this._loggerService.log('Redirect to /login/get-state');
    this._baseRoutingService.goToLoginPage();
  }

  goToWebclientPage(): void {
    this._loggerService.log('Redirect to /webclient');
    this._baseRoutingService.goToWebclientPage();
  }

  hideContextMenu(): void {
    if (this._contextMenuService.isOpening) {
      this._loggerService.log('Clicked on app, try to hide existing context menus...');
      this._contextMenuService.hideContextMenu();
    }
  }

  initVersionCheckingTimestamp(): void {
    this._versionCheckingService.setLastVersionCheckingTimestamp();
  }

  performVersionMigration(newVersionCode: number): void {
    if (!newVersionCode) {
      return;
    }

    if (newVersionCode >= 230025) {
      if (this._localStorageManagerService.getSessionStorageByKey('PASSWORD')) {
        this._localStorageManagerService.setLocalStorageByKey(
          TeamNoteLocalStorageKeyConstants.SESSION.PASSWORD, this._localStorageManagerService.getSessionStorageByKey('PASSWORD'));
        this._localStorageManagerService.removeSessionStorageByKey('PASSWORD');
      }
    }
  }

  checkVersion(): void {
    this._versionCheckingService.checkVersion();
  }

  // Capture on click for <a> tag
  handleWindowOnClickForTeamNoteLinks(): void {
    window.onclick = (event: MouseEvent) => {
      const target: any = event.target;

      let href = this.findTargetHref(target)
      if (href) {
        if (this._tnLinkHelperService.checkIfLinkIsTeamNoteLink(href)) {
          event.preventDefault();
          this._tnLinkHelperService.handleTeamNoteLink(href);
        }
      }
    };
  }

  findTargetHref(el: any): string | null {
    let targetEl: any = el
    let href = targetEl.href || null

    // check parent element until find the <a> tag
    while(targetEl.localName !== 'a') {
      targetEl = targetEl.parentElement

      if (!targetEl) {
        break;
      }

      if (targetEl.href) {
        href = targetEl.href
        break;
      } 
    }

    return href
  }

  /**
   * Clear clipboard by assigning 'space' to clipboard if external_copy is not enabled
   *
   * @returns {void}
   * @memberof AppComponent
   */
  clearClipboard(): void {
    // Do not try to clear if not locally enabled
    if (!this._teamnoteConfigService.config.GENERAL.IS_RESTRICT_CLIPBOARD) {
      return;
    }
    // Do not try to clear if not yet logged in
    if (!this._accountManagerService.isLoggedIn) {
      return;
    }
    if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.EXTERNAL_COPY)) {
      // enabled external copy, do not clear clipboard
      return;
    }
    this._utilitiesService.copyValueToClipboard(' ');
  }

}
